import React, { useState } from "react";
import styled from "styled-components";
import { BackgroundImage } from "../../authentication/shared/SharedStyles";
import { colors } from "../../../styles/style";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import { useNavigate } from "react-router-dom";
import clouds from "../../../images/clouds.svg";
import {
  Option,
  FlexRowBetweenCenter,
  BigHeaderText,
  SwitchContainer,
  MediumIcon,
} from "../../shared/SharedStyles";
import SwipeableViews from "react-swipeable-views";
import { MonthlyDiaryList } from "./common/MonthlyDiaryList";
import { MilestoneButtonWrapper } from "../common/SharedComponents";

export const MonthlyDiary = () => {
  const currentYear = new Date().getFullYear().toString();
  const [selectedYear, setSelectedYear] = useState<string>(currentYear);
  const navigate = useNavigate();
  const initialYearGroupIndex = 3;
  const [yearGroupIndex, setYearGroupIndex] = useState<number>(4);
  const years = ["2020", "2021", "2022", "2023", "2024", currentYear];

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleChangeIndex = (index: number) => {
    const newIndex = yearGroupIndex + index;
    if (newIndex >= 0 && newIndex <= years.length - 3) {
      setYearGroupIndex(newIndex);
    }
  };

  return (
    <React.Fragment>
      <Container>
        <BackgroundImage
          $blend_mode=""
          $position="absolute"
          $left="0"
          $top="0"
          $transform="translate(-50%, -50%)"
          $width="390px"
          alt="Frame"
          src={clouds}
        />
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>Monthly Diary</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <SwitchContainer>
          <SwipeableViews
            index={yearGroupIndex - initialYearGroupIndex}
            onChangeIndex={handleChangeIndex}
          >
            {[0, 1].map((index) => (
              <YearGroup key={index}>
                {years.slice(index * 3, index * 3 + 3).map((year) => (
                  <Option
                    key={year}
                    $active={selectedYear === year ? "true" : "false"}
                    onClick={() => setSelectedYear(year)}
                  >
                    {year}
                  </Option>
                ))}
              </YearGroup>
            ))}
          </SwipeableViews>
        </SwitchContainer>

        <MonthlyDiaryList selectedYear={selectedYear} />
        <MilestoneButtonWrapper>
          <Button $button_type="colored" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </Container>
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  min-height: 100vh;
  padding-top: 40px;
  background-color: ${colors.bgWhite};
`;

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const YearGroup = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;
